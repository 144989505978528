import Status from '../Status.js';
import { SET_TEAM, SET_STATUS } from './TeamReducer.js';
import ApiService from '../../services/ApiService.js';
import { setErrors } from '../Errors/ErrorsActions.js';

const setStatus = status => ({
  type: SET_STATUS,
  status,
});

const setTeam = team => ({
  type: SET_TEAM,
  team,
});

export const loadTeam = (sportId, competitionId) => async dispatch => {
  dispatch(setStatus(Status.Loading));

  const { sport, team } = await ApiService.getInstance().getTeam(sportId, competitionId);

  if (!team) {
    return dispatch(setErrors(['Failed to fetch team']));
  }

  dispatch(setTeam({ sport, team }));
};
