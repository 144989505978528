import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../../../../components/Layout.js';
import Loading from '../../../../../components/Loading.js';
import Title from '../../../../../components/typography/Title.js';
import TallContainer from '../../../../../components/TallContainer.js';
import { loadTeam } from '../../../../../stores/Team/TeamActions.js';
import { useTeam } from '../../../../../stores/Team/TeamSelectors.js';
import Status, { isLoadingStatus } from '../../../../../stores/Status.js';
import { useExistingBanner } from '../../../../../stores/ExistingBanners/ExistingBannersSelectors.js';
import ExistingBanner from '../../../../../components/existing-banners/ExistingBanner.js';
import BannerForm, { BannerTypes } from '../../../../../components/forms/BannerForm.js';
import { loadTeamBanner } from '../../../../../stores/ExistingBanners/ExistingBannersActions.js';

const TeamBannerDelegate = () => {
  const dispatch = useDispatch();
  const { team } = useTeam();
  const { status } = useExistingBanner();

  useEffect(() => {
    dispatch(loadTeamBanner({ teamId: team.id, sportId: team.sport.id })).then();
  }, [team.id, team.sport.id, dispatch]);

  if (isLoadingStatus(status)) {
    return <Loading />;
  }

  if (status === Status.OK) {
    return <ExistingBanner type={BannerTypes.Team} sport={team.sport} team={team} />;
  }

  return (
    <div>
      <p className="banner__description">
        There isn't an active banner for {team.name} at the moment. You can add one below...
      </p>
      <BannerForm
        type={BannerTypes.Team}
        sport={team.sport}
        teamId={team.id}
      />
    </div>
  );
};

const TeamLoader = ({ sportId, teamId }) => {
  const dispatch = useDispatch();
  const { status } = useTeam();

  useEffect(() => {
    if (sportId && teamId) {
      dispatch(loadTeam(sportId, teamId)).then();
    }
  }, [sportId, teamId, dispatch]);

  if (isLoadingStatus(status)) {
    return <Loading message="Loading team..." />;
  }

  return <TeamBannerDelegate />;
};

const withTeamTitle = () => () => {
  const { team: { name } = {} } = useTeam();

  return name
    ? <Title black={name} blue="Banner" />
    : null;
};

export default function TeamBanner({ params: { sportId, teamId } }) {
  if (!teamId || !sportId) {
    return <Loading />;
  }

  return (
    <Layout TitleElement={withTeamTitle()}>
      <TallContainer>
        <TeamLoader teamId={teamId} sportId={sportId} />
      </TallContainer>
    </Layout>
  );
};
