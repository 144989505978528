import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const createTeamSelector = () => createSelector(
  ({ team }) => team,
  team => team,
);

export const useTeam = () => {
  const selector = useMemo(createTeamSelector, []);
  const { team: { team, sport } = {}, status } = useSelector(selector);
  return { status, team: { ...team, sport } };
};
